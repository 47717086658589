/* eslint-disable react-hooks/exhaustive-deps  */
import { useEffect, useState } from 'react';
import QueryString from 'query-string';
import { Environment } from '../constants';

export function useKeepUiVersionInQueryStringIfPresent(history) {
  let queryParams = QueryString.parse(history.location.search);

  const [initialUiVersion] = useState(queryParams.ui_version);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === Environment.STAGING) {
      const listener = location => {
        queryParams = QueryString.parse(location.search);
        const { ui_version: uiVersion } = queryParams;
        if (process.env.REACT_APP_NODE_ENV !== 'production' && !uiVersion) {
          history.replace({
            pathname: location.pathname,
            search: QueryString.stringify({
              ...queryParams,
              ui_version: uiVersion || initialUiVersion,
            }),
          });
        }
      };

      listener(window.location);
      return history.listen(listener);
    }
  }, [history]);
}
