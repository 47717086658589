export const {
  ASSETS_PATH,
  REACT_APP_GOOGLE_ANALYTICS: GOOGLE_ANALYTICS,
  REACT_APP_FACEBOOK_PIXEL: FACEBOOK_PIXEL,
  REACT_APP_CLEVERTAP_PROJECT_ID: CLEVERTAP_PROJECT_ID,
  REACT_APP_SENTRY_DNS: SENTRY_DNS,
  REACT_APP_CURRENCY: CURRENCY,
  REACT_APP_SEGMENT_KEY: SEGMENT_KEY,
  REACT_APP_SEGMENT_SOURCE: SEGMENT_SOURCE,
} = process.env;
