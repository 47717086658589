/* eslint-disable class-methods-use-this */
import baseI18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation } from 'react-i18next';
import { ASSETS_PATH } from '../constants';

class I18n {
  init() {
    return (
      baseI18n
        // load translation using http -> see /public/locales
        // learn more: https://github.com/i18next/i18next-http-backend
        .use(Backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
          backend: {
            loadPath: `${ASSETS_PATH}/locales/{{lng}}/{{ns}}.json`,
          },
          fallbackLng: 'es-CO',
          debug: true,
          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          },
        })
    );
  }

  getText(key) {
    const { t } = useTranslation();
    return t(key);
  }
}

const i18n = new I18n();

export { i18n };
