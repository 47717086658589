/* eslint-disable no-underscore-dangle */
import clevertap from 'clevertap-web-sdk';
import { captureException, setUser, init as initSentry } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import {
  CLEVERTAP_PROJECT_ID,
  CURRENCY,
  FACEBOOK_PIXEL,
  GOOGLE_ANALYTICS,
  SENTRY_DNS,
} from '../constants';

const CommonEventNames = {
  ERROR: 'App Error',
};

const AnalyticsConstants = {
  ECOM_PLUGIN_KEY: 'ec',
};

class SfEvents {
  constructor() {
    this._sentryReady = false;
    this._clevertapReady = false;
    this._analyticsReady = false;
    this._pixelReady = false;
  }

  /*
    history: React-router history component
    environment: Should be local, staging or production
    enableDebug: if true enable debug features on libraries (defaults to false)
   */
  load({ history, environment, enableDebug = false, enableEvents = true }) {
    this.environment = environment;
    this.history = history;
    this.enableDebug = enableDebug;
    if (enableEvents) {
      this._initSentry();
      if (this._sentryReady) {
        this._initCleverTap();
        this._initGoogleAnalytics();
        this._initPixel();

        this.history.listen(location => {
          const pathname = location.pathname === '/' ? 'Home' : location.pathname;
          if (this._analyticsReady) {
            ReactGA.plugin.require(AnalyticsConstants.ECOM_PLUGIN_KEY);
            ReactGA.set({ currencyCode: CURRENCY || 'COP' });
            ReactGA.set({ page: pathname }); // Update the user's current page
            ReactGA.pageview(pathname);
          }
          if (this._pixelReady) {
            ReactPixel.track('page_view', pathname);
          }
          if (this._clevertapReady) {
            clevertap.event.push('page_view', pathname);
          }
        });
      }
    }
  }

  _initSentry() {
    if (SENTRY_DNS) {
      initSentry({
        dsn: SENTRY_DNS,
        environment: this.environment,
        integrations: [new Integrations.BrowserTracing()],
      });
      // eslint-disable-next-line no-console
      console.debug('Sentry successfully initiated');
      this._sentryReady = true;
    }
  }

  _initCleverTap() {
    if (!CLEVERTAP_PROJECT_ID) {
      if (this.environment === 'production') {
        captureException(new Error('Missing REACT_APP_CLEVERTAP_PROJECT_ID env var'));
      }
    } else {
      clevertap.privacy.push({ optOut: false });
      clevertap.privacy.push({ useIP: true });
      clevertap.init(CLEVERTAP_PROJECT_ID, 'us1');
      // eslint-disable-next-line no-console
      console.debug('Clevertap successfully initiated');
      this._clevertapReady = true;
    }
  }

  _initGoogleAnalytics() {
    if (!GOOGLE_ANALYTICS) {
      if (this.environment === 'production') {
        captureException(new Error('Missing REACT_APP_GOOGLE_ANALYTICS env var'));
      }
    } else {
      ReactGA.initialize(GOOGLE_ANALYTICS, {
        standardImplementation: true,
      });
      // eslint-disable-next-line no-console
      console.debug('GA successfully initiated');
      this._analyticsReady = true;
    }
  }

  _initPixel() {
    if (!FACEBOOK_PIXEL) {
      if (this.environment === 'production') {
        captureException(new Error('Missing REACT_APP_FACEBOOK_PIXEL env var'));
      }
    } else {
      ReactPixel.init(
        FACEBOOK_PIXEL,
        { country: 'co' },
        {
          debug: this.enableDebug,
          autoConfig: false,
        },
      );
      // eslint-disable-next-line no-console
      console.debug('Pixel successfully initiated');
      this._pixelReady = true;
    }
  }

  /*
    eventName: name of the event to be tracked
    opts: (optional) key/value pairs as properties on the event
   */
  trackEvent(eventName, opts = {}) {
    try {
      if (this._clevertapReady) {
        clevertap.event.push(eventName, opts);
      }
      if (this._pixelReady) {
        ReactPixel.track(eventName, opts);
      }
      if (this._analyticsReady) {
        ReactGA.plugin.execute(AnalyticsConstants.ECOM_PLUGIN_KEY, eventName, opts);
      }
      // TODO add adjust integration
    } catch (err) {
      captureException(err);
    }
  }

  /*
  error: Javascript error to be captured
  opts: (optional) key/value pairs as properties on the event
 */
  trackError(error, opts) {
    if (this._clevertapReady) {
      clevertap.event.push(CommonEventNames.ERROR, opts);
    }
    if (this._sentryReady) {
      captureException(error, opts);
    }
  }

  /*
    user: { id, email, name, phone }
  */
  configureUser(user) {
    if (this._sentryReady) {
      setUser(user);
    }
    if (this._clevertapReady) {
      clevertap.onUserLogin.push({
        Site: {
          Identity: user.id,
          Name: user.name,
          Phone: user.phone,
          Email: user.email,
        },
      });
    }
  }
}

const eventsClient = new SfEvents();

export { eventsClient };
