/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import QueryString from 'query-string';

export function useKeepClientIdInQueryStringIfPresent(history) {
  let queryParams = QueryString.parse(history.location.search);

  const [initialClientId] = useState(queryParams.client_id);

  useEffect(() => {
    const listener = location => {
      queryParams = QueryString.parse(location.search);
      const { client_id: clientId } = queryParams;
      if (!clientId && initialClientId) {
        history.replace({
          pathname: location.pathname,
          search: QueryString.stringify({
            ...queryParams,
            client_id: clientId || initialClientId,
          }),
        });
      }
    };

    listener(window.location);
    return history.listen(listener);
  }, [history]);
}
